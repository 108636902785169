<template>
  <div class="background">
    <div class="phone_header">
      <div class="logo"><img src="../../assets/img/logo.png" alt="" /></div>
      <div class="nav">
        <!-- <div>中文</div> -->
        <div>
          <a
            :href="
              $i18n.locale == 'en'
                ? 'https://docs.osai.cc/web/en/'
                : 'https://docs.osai.cc'
            "
            target="_blank"
            >文档中心</a
          >
        </div>
        <div><a href="https://ucp.osai.cc" target="_blank">云平台登录</a></div>
      </div>
    </div>

    <div class="phone_con">
      <div class="banner">
        <div class="banner_text">
          傲视OSAI专注于新零售领域的AI人工智能技术的研发和实际应用，帮助众多客户极大地提升了经营效率。
        </div>
        <div class="img">
          <img src="../../assets/img/banner/right-mobile1.png" alt="" />
        </div>
        <div class="img">
          <img src="../../assets/img/banner/right-mobile3.png" alt="" />
        </div>
        <div class="phone_footer">
          <div class="gongan">
            <img src="../../assets/img/goods/gongan.png" alt="" />
            <a href="https://www.beian.gov.cn/portal/index.do" target="_blank"
              >粤公网安备 44049102496925号</a
            >
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <a href="https://beian.miit.gov.cn/" target="_blank">
            粤ICP备2021150212号-1
          </a>
        </div>
        <div class="copy">
          Copyright © 2024 珠海傲视创新科技有限公司 OSAI Inc. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.background {
  background: url(../../assets/img/banner/home-banner.png) no-repeat;
  background-size: cover;
}
.phone_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 68px;
  padding: 0px 60px;
  img {
    width: 200px;
  }
  .nav {
    display: flex;
    div {
      color: rgb(51, 51, 51);
      font-size: 35px;
      padding: 0px 10px;
    }
  }
}

.phone_con .banner {
  width: 100%;
  .banner_text {
    font-size: 80px;
    line-height: 160px;
    color: rgb(51, 51, 51);
    margin: 0px auto;
    padding-top: 200px;
    width: 900px;
  }
  .img {
    margin-top: 80px;
    padding: 0px 50px;
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.phone_footer {
  padding: 80px 0px;
  font-size: 32px;
  color: #cce;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  .gongan {
    display: flex;
    justify-content: center;
    align-content: center;
    img {
      border: currentColor;
      border-image: none;
    }
  }
}

.copy {
  display: flex;
  justify-content: center;
  font-size: 32px;
  color: #cce;
  padding-bottom: 20px;
}
</style>
